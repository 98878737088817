<template>
  <div>
    <Table
      label="Multiple Sections"
      :actions="actions"
      :items="$store.getters['multipleSection/tableData']"
      :detail="$store.getters['multipleSection/detailData']"
      :fields="$store.getters['multipleSection/tableFields']"
      :loading="$store.state.multipleSection.loadingTable"
      :filter="$store.state.multipleSection.tableFilter"
      :sort="$store.state.multipleSection.tableSort"
      :sort-options="sortOptions"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @loadDetail="loadDetail"
      @resetDetail="resetDetail"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      actions: ['view', 'create', 'update'],
    }
  },
  methods: {
    changeFields(val) {
      this.$store.commit('multipleSection/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('multipleSection/RESET_TABLE_FIELDS')
    },
    loadData() {
      return this.$store.dispatch('multipleSection/getTableData')
    },
    filterData(val) {
      this.$store.commit('multipleSection/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('multipleSection/SET_TABLE_SORT', val)
    },
    loadDetail(id) {
      return this.$store.dispatch('multipleSection/getDetail', id)
    },
    resetDetail() {
      this.$store.commit('multipleSection/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>
